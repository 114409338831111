<template>
    <div class="page-content">
        <div class="grid">
            <div class="row no-gutters page-list-header">
                <div class="col l-6 m-6 c-12">
                    <h4 class="page-list-title"><md-icon>note_add</md-icon> <span>{{title}}</span></h4>
                </div>
                <div class="col l-6 m-6 c-12">
                    <div class="page-list-action"> 
                        <md-button v-shortkey="['ctrl', 's']" @shortkey="submit(true)" @click="submit(true)" class="md-raised md-primary"><span>L</span>ưu<md-tooltip>Lưu thông tin (Ctrl + S)</md-tooltip></md-button>
                        <md-button v-shortkey="['ctrl', 'l']" @shortkey="submit(false)" @click="submit(false)" class="md-raised md-temp"><span>L</span>ưu tạm thời<md-tooltip>Lưu tạm thời (Ctrl + L)</md-tooltip></md-button>
                        <md-button v-shortkey="['ctrl', 'i']" @shortkey="back()" @click="back()" class="md-raised">Quay lạ<span>i</span><md-tooltip>Quay lại (Ctrl + Q)</md-tooltip></md-button>
                    </div>
                </div>
            </div>
            <div class="page-list-body">
                <div class="form-body">
                    <div class="row">
                       <div class="col l-3 m-3 c-12">
                            <md-field :class="{'md-invalid': submitted && $v.entity.contractCode.$error }">
                                <label for="contractCode">Mã hợp đồng</label>
                                <md-input disabled name="contractCode" v-model="entity.contractCode"></md-input>
                                <span class="md-error" v-if="submitted && !$v.entity.contractCode.required">Vui lòng nhập mã hợp đồng</span>
                            </md-field>

                            <md-field :class="{'md-invalid': submitted && $v.entity.contractName.$error }">
                                <label for="contractName">Số hợp đồng</label>
                                <md-input name="contractName" v-model="entity.contractName"></md-input>
                                <span class="md-error" v-if="submitted && !$v.entity.contractName.required">Vui lòng nhập số hợp đồng</span>
                            </md-field>
                        </div>
                        <div class="col l-3 m-3 c-12">
                            <div class="form-control">
                                <md-autocomplete v-model="customerName" @md-selected="getCustomerSelected" :md-options="customers" @md-changed="getCustomers"  @md-opened="getCustomers" :class="{'md-invalid': submitted && $v.entity.customerId.$error }">
                                    <label>Khách hàng</label>
                                    <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                        <md-highlight-text :md-term="term">{{ item.companyAlias }}</md-highlight-text>
                                    </template>
                                    <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                        "{{ term }}" Không tìm thấy!
                                    </template>
                                    <span class="md-error" v-if="submitted && !$v.entity.customerId.isSelected">Vui lòng chọn khách hàng</span>
                                </md-autocomplete>
                                <md-button @click="openCustomer()" class="md-icon-button">
                                    <md-icon>manage_search</md-icon>
                                </md-button>
                            </div>

                            <div class="form-control">
                                <md-autocomplete v-model="staffName" @md-selected="getStaffSelected" :md-options="staffs" @md-changed="getStaffs"  @md-opened="getStaffs" :class="{'md-invalid': submitted && $v.entity.staffId.$error }">
                                    <label>Nhân viên phụ trách</label>
                                    <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                        <md-highlight-text :md-term="term">{{ item.fullName }}</md-highlight-text>
                                    </template>
                                    <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                        "{{ term }}" Không tìm thấy!
                                    </template>
                                    <span class="md-error" v-if="submitted && !$v.entity.staffId.isSelected">Vui lòng chọn nhân viên phụ trách</span>
                                </md-autocomplete>
                                <md-button @click="openStaff()" class="md-icon-button">
                                    <md-icon>manage_search</md-icon>
                                    <md-tooltip>Tìm nhân viên</md-tooltip>
                                </md-button>
                            </div>
                        </div>
                        <div class="col l-3 m-3 c-12">
                            <div class="form-control">
                                <md-autocomplete v-model="typeName" @md-selected="getTypeSelected" :md-options="types" @md-changed="getTypes"  @md-opened="getTypes" :class="{'md-invalid': submitted && $v.entity.typeCode.$error }">
                                    <label>Loại hợp đồng</label>
                                    <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                        <md-highlight-text :md-term="term">{{ item.name }}</md-highlight-text>
                                    </template>
                                    <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                        "{{ term }}" Không tìm thấy!
                                    </template>
                                    <span class="md-error" v-if="submitted && !$v.entity.typeCode.required">Vui lòng chọn loại hợp đồng</span>
                                </md-autocomplete>
                                <md-button @click="openType()" class="md-icon-button">
                                    <md-icon>manage_search</md-icon>
                                </md-button>
                            </div>
                            <div class="radio-group">
                                <label>Khách hàng/Vendor</label>
                                <div class="form-control ">
                                    <md-radio v-model="entity.isVendor" :value="false">Khách hàng</md-radio>
                                    <md-radio v-model="entity.isVendor" :value="true">Vendor</md-radio>
                                </div>
                            </div>
                        </div>
                        <div class="col l-3 m-3 c-12">
                            <md-datepicker v-model="entity.startDate" md-immediately :md-model-type="String" :class="{'md-invalid': submitted && $v.entity.startDate.$error }">
                                <label>Ngày bắt đầu</label>
                                <span class="md-error" v-if="submitted && !$v.entity.startDate.required">Vui lòng chọn bắt đầu</span>
                            </md-datepicker>
                            <md-datepicker v-model="entity.endDate" md-immediately :md-model-type="String">
                                <label>Ngày kết thúc</label>
                            </md-datepicker>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col l-3 m-3 c-12">
                            <div class="radio-group">
                                <md-field>
                                    <label>File HĐ</label>
                                    <md-file v-model="fileUpload" @md-change="upload($event)"/>
                                    <md-button v-if="entity.files.length > 0" @click="clearFile()" class="md-icon-button">
                                        <md-icon>clear</md-icon>
                                    </md-button>
                                </md-field>
                            </div>
                        </div>
                        <div class="col l-3 m-3 c-12">
                            <md-field>
                                <label for="contractName">Nơi để file HĐ</label>
                                <md-input name="filePath" v-model="entity.filePath"></md-input>
                            </md-field>
                        </div>
                    </div>
                </div>
                <div class="form-body form-tab">
                    <div class="row">
                        <div class="col l-12 m-12 c-12">
                            <md-tabs>
                                <md-tab id="tab-owner" class="tab-owner" md-label="Chủ hàng">
                                    <div class="form-body">
                                        <div class="row">
                                            <div class="col l-12 m-12 c-12">
                                                <div class="tool-bar">
                                                    <md-button @click="addOwner()" class="md-primary"><md-icon>add_card</md-icon> Thêm dòng<md-tooltip>Thêm dòng</md-tooltip></md-button>
                                                </div>
                                                <table class="data-table-2"> 
                                                    <thead> 
                                                        <tr> 
                                                            <th style="width:100px;">#</th> 
                                                            <th style="width:28%;">Mã chủ hàng</th> 
                                                            <th style="width:30%;">Tên viết tắt</th> 
                                                            <th style="width:30%;">Tên chủ hàng</th> 
                                                            <th style="width:120px">Hành động</th> 
                                                        </tr> 
                                                    </thead> 
                                                    <tbody v-if="loadding == true" style="height: 100px;"> 
                                                        <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                                                    </tbody>
                                                    <tbody v-if="loadding == false"> 
                                                        <tr v-for="(item, index) in entity.owners" :key="item.id" :class="{ 'odd': index % 2 !== 0 }"> 
                                                            <td class="center">{{index + 1}}</td> 
                                                            <td class="center">
                                                                <div class="grid-control">
                                                                    <input v-model="item.companyCode" class="form-control" type="text">
                                                                    <md-button @click="openOwner(item.id)" class="md-icon-button">
                                                                        <md-icon>manage_search</md-icon>
                                                                        <md-tooltip>Tìm chủ hàng</md-tooltip>
                                                                    </md-button>
                                                                </div>
                                                            </td> 
                                                            <td class="center">{{item.companyAlias}}</td> 
                                                            <td class="center">{{item.companyName}}</td> 
                                                            <td class="grid-action">
                                                                <a @click="delOwner(item)"><md-icon>clear</md-icon><md-tooltip>Xóa dòng</md-tooltip></a>
                                                            </td> 
                                                        </tr> 
                                                    </tbody> 
                                                </table> 
                                            </div>
                                        </div>
                                    </div>
                                </md-tab>
                            </md-tabs>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <staffList ref="staffList" @close="closeStaff"/>
        <typeList ref="typeList" :group="group" title="loại hợp đồng" @close="closeType"/>
        <customerList ref="customerList" title="khách hàng" :type="companyType" @close="closeCustomer"/>
        <crudForm ref="crudForm" @close="closeCrud"/>
        <ownerList ref="ownerList" title="chủ hàng" :type="ownerType" @close="closeOwner"/>

        <md-dialog :md-active.sync="uploading" :md-click-outside-to-close="false" :md-close-on-esc="false">
            <md-dialog-title>Uploading...</md-dialog-title>
            <md-content>
                <div class="progress-percent">Uploading <span style="color:red;">{{this.uploadedSize}} MB</span> of <span style="color:red;">{{this.totalSize}} MB</span></div>
                <div class="progress-bar">
                    <progress-bar :options="options" :value="progress" /> 
                </div>
            </md-content>
        </md-dialog>
   </div>
</template>
<script>
    import uploadService from '../../../api/uploadService';
    import contractService from '../../../api/contractService';
    import typeService from '../../../api/typeService';
    import staffService from '../../../api/staffService';
    import companyService from '../../../api/companyService';
    import messageBox from '../../../utils/messageBox';
    import { required } from 'vuelidate/lib/validators';
    import { mapActions } from 'vuex';
    import common from '../../../mixins';
    import staffList from '../../../components/popup/_StaffList.vue';
    import typeList from '../../../components/popup/_TypeList.vue';
    import customerList from '../../../components/popup/_CompanyList.vue';
    import ownerList from '../../../components/popup/_CompanyList.vue';
    import crudForm from './CrudFee.vue';

    export default ({
        components: {
            staffList,
            typeList,
            customerList,
            ownerList,
            crudForm
        },
        metaInfo: {
            title: 'Thêm/Cập nhật hợp đồng'
        },
        data() {
            return {
                title: '',
                id: 0,
                loadding: false,
                submitted: false,
                group: common.groupCode.contract,
                companyType: common.companyType.customer,
                ownerType: common.companyType.customer,
                entity: { id: 0, contractNumber: 0, orderType: 0, owners: [], files: [], feeList: [], customerId: 0, staffId: 0, contractCode: '', isVendor: false, contractName: '', typeCode: '', startDate: null, endDate: null, isActive: false },
                staffName: '',
                staffs: [],
                typeName: '',
                types: [],
                customerName: '',
                customers: [],
                fileChunk: [],
                totalFileChunk: 0,
                maxFileSizeMB: 5,
                uploading: false,
                file: null,
                options: {
                    text: {
                        color: '#FFFFFF',
                        shadowEnable: true,
                        shadowColor: '#000000',
                        fontSize: 10,
                        fontFamily: 'Helvetica',
                        dynamicPosition: false,
                        hideText: false
                    },
                    progress: {
                        color: '#2dbd2d',
                        backgroundColor: 'rgb(235, 238, 245)'
                    },
                    layout: {
                        height: 15,
                        width: '100%',
                        verticalTextAlign: 70,
                        horizontalTextAlign: 43,
                        zeroOffset: 0,
                        strokeWidth: 20,
                        progressPadding: 0,
                        type: 'line'
                    }
                },
                progress: 10,
                uploadedSize: 0,
                totalSize: 0,
                fileUpload: null,
                orderType: 1
            }
        },
        created(){
            this.orderType = this.$route.query.orderType == undefined ? 1 :  this.$route.query.orderType;
            this.id = this.$route.params.id;
            if(this.id > 0){
                this.title = this.orderType == 1 ? "Cập nhật hợp đồng hàng thường" : "Cập nhật hợp đồng hàng siêu trường siêu trọng";
                this.getById();
            }
            else{
                this.getCode();
                this.title = this.orderType == 1 ? "Thêm mới hợp đồng hàng thường" : "Thêm mới hợp đồng hàng siêu trường siêu trọng";
            }
        },
        methods: {
            ...mapActions('common', ['setLoading']),

            openOwner(id){
                this.selectedId = id;
                this.$refs.ownerList.open();
            },

            closeOwner(item){
                const selected = this.entity.owners.findIndex(x => x.id == this.selectedId);
                this.entity.owners[selected].id = item.id;
                this.entity.owners[selected].companyCode = item.companyCode;
                this.entity.owners[selected].companyAlias = item.companyAlias;
                this.entity.owners[selected].companyName = item.companyName;
                this.$refs.ownerList.close();
                this.selectedId = '';
            },

            //Del Owner
            delOwner(item){
                const index = this.entity.owners.findIndex(x => x.id == item.id);
                this.entity.owners.splice(index, 1);
            },

            //Add Owner
            addOwner(){
                let item = { id: 'id_' + common.getFakeId(), contractId: 0,companyCode: '', companyAlias: '', companyName: '' };
                this.entity.owners.push(item);
            },

            delFee(id){
                const index = this.entity.feeList.findIndex(x => x.id == id);
                this.entity.feeList.splice(index, 1);
            },

            closeCrud(obj, action){
                console.log(obj)
                this.entity.feeList.push(obj);
                if(!action){
                    this.$refs.crudForm.close();
                }
            },

            editFee(obj){
                this.$refs.crudForm.open(obj.id);
            },

            addFee(){
                this.$refs.crudForm.open(0);
            },

            clearFile(){
                this.fileUpload = null;
                this.entity.files = []
            },

            upload(evet) {
                this.progress = 0;
                this.uploadedSize = 0;
                this.file = evet[0];
                var bufferChunkSize = this.maxFileSizeMB * (1024 * 1024);    
                var fileStreamPos = 0;  
                var endPos = bufferChunkSize;  
                var size = this.file.size;
                this.totalSize =  Math.round(this.file.size / (1024 * 1024));
                while (fileStreamPos < size)  
                {  
                    this.fileChunk.push(this.file.slice(fileStreamPos, endPos));  
                    fileStreamPos = endPos; 
                    endPos = fileStreamPos + bufferChunkSize; 
                }
                this.totalFileChunk = this.fileChunk.length;
            },

            uploadChunk(chunk, fileName) {
                this.uploading = true;
                var formData = new FormData();  
                formData.append('file', chunk, fileName);  
                uploadService.upload(formData).then((response) => {
                    if (response.data.statusCode == 200) {
                        this.fileChunk.shift();
                        if(this.fileChunk.length == 0){
                            this.progress = 100;
                            this.uploadedSize = this.totalSize;
                        }
                        else{
                            this.uploadedSize += this.maxFileSizeMB;
                            this.progress = Math.round((this.uploadedSize/ this.totalSize)*100);
                        }
                        if (this.fileChunk.length == 0 && response.data.data != null && response.data.data.id > 0) {
                            this.uploading = false;
                            this.uploading = false;
                            this.progress = 0;
                            this.uploadedSize = 0;
                            this.entity.files.push({ id: response.data.data.id, fileName: response.data.data.fileName });
                            messageBox.showMessage("Upload thành công");
                        }
                    }
                    else {
                        this.uploading = false;
                        this.progress = 0;
                        this.uploadedSize = 0;
                        messageBox.showWarning(response.data.data);
                    }
                }).finally();
            },

            //Customer
            closeCustomer(item){
                this.customerName = item.companyAlias;
                this.entity.customerId = item.id;
                this.$refs.customerList.close();
            },

            getCustomerSelected(val){
                this.entity.customerId = val.id;
                this.customerName = val.companyAlias;
            },

            openCustomer(){
                this.$refs.customerList.open(7);
            },

            getCustomers(searchTerm){
                let search = { pageIndex: 1, pageSize: 30, companyType: common.companyType.customer, code: searchTerm };
                companyService.getCompanies(search).then((response) => {
                    if(response.statusCode == 200){
                        this.customers = response.data.items;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },
            
            //Type
            closeType(item){
                this.typeName = item.name;
                this.entity.typeCode = item.code;
                this.$refs.typeList.close();
            },

            getTypeSelected(val){
                this.entity.typeCode = val.code;
                this.typeName = val.name;
            },

            openType(){
                this.$refs.typeList.open();
            },

            getTypes(searchTerm){
                typeService.getTypes(this.group, searchTerm).then((response) => {
                    if(response.statusCode == 200){
                        this.types = response.data;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },

            //Sales
            closeStaff(item){
                this.staffName = item.fullName;
                this.entity.staffId = item.id;
                this.$refs.staffList.close();
            },

            getStaffSelected(val){
                this.entity.staffId = val.id;
                this.staffName = val.fullName;
            },

            openStaff(){
                this.$refs.staffList.open();
            },

            getStaffs(searchTerm){
                let search = { pageIndex: 1, pageSize: 30, name:  searchTerm };
                staffService.getStaffs(search).then((response) => {
                    if(response.statusCode == 200){
                        this.staffs = response.data.items;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },

            getCode(){
                this.setLoading(true);
                contractService.getCode().then((response) => {
                    if(response.statusCode == 200){
                        this.entity.contractCode = response.data.code;
                        this.entity.contractNumber = response.data.number;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            submit(isActive){
                this.submitted = true;
                this.$v.$touch();
                if (this.$v.$invalid) {
                    return;
                }
                this.entity.isActive = isActive;
                if(this.id > 0){
                    this.edit();
                }
                else{
                    this.add();
                }
            },

            add(){
                this.setLoading(true);
                this.entity.orderType = parseFloat(this.orderType);
                contractService.add(this.entity).then((response) => {
                    if(response.statusCode == 200){
                        messageBox.showMessage("Thêm mới thành công");
                        this.$router.push('/contract?orderType=' + this.orderType);
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                 }).finally(() => { this.setLoading(false); });
            },

            edit(){
                this.setLoading(true);
                contractService.edit(this.entity).then((response) => {
                    if(response.statusCode == 200){
                        messageBox.showMessage("Cập nhật thành công");
                        this.$router.push('/contract?orderType=' + this.orderType);
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            getById(){
                this.setLoading(true);
                contractService.getById(this.id).then((response) => {
                    if(response.statusCode == 200){
                        this.entity = response.data;
                        this.typeName = response.data.type.name;
                        this.customerName = response.data.customer.companyAlias;
                        this.staffName = response.data.staff.fullName;
                        if(response.data.files.length > 0){
                            this.fileUpload = response.data.files[0].fileName;
                        }
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },
            back(){
                this.$router.push('/contract?orderType=' + this.orderType);
            }
        },
        watch: { 
            staffName: function (val) { 
                if(val == ''){
                    this.entity.staffId = 0;
                }
            },
            customerName: function (val) { 
                if(val == ''){
                    this.entity.customerId = 0;
                }
            },
            typeName: function (val) { 
                if(val == ''){
                    this.entity.typeCode = '';
                }
            },
            fileChunk(n) {
                if (n.length > 0) {
                    var partCount = this.totalFileChunk - n.length + 1;
                    var filePartName = this.file.name + ".part_" + partCount + "." + this.totalFileChunk;
                    this.uploadChunk(this.fileChunk[0], filePartName);
                }
            },
        },
        validations: {
            entity: {
                customerId: {
                    required,
                    isSelected(value) {
                        if (value > 0) {
                            return true;
                        }
                        else{
                            return false;
                        }
                    }
                },
                staffId: {
                    required,
                    isSelected(value) {
                        if (value > 0) {
                            return true;
                        }
                        else{
                            return false;
                        }
                    }
                },
                contractCode: { required },
                contractName: { required },
                typeCode: { required },
                startDate: { required }
            }
       }
     })

</script>

<style scoped>
    .md-dialog .md-content {
        width: 350px;
        padding: 0 20px 20px;
    }
</style>