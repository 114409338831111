<template>
    <md-dialog :md-active.sync="showDialog" :md-click-outside-to-close="false">
        <md-content>
            <div class="dialog-header">
                <div class="dialog-title">{{ title }}</div>
                <div class="dialog-close" @click="showDialog = false">
                    Close
                </div>
            </div>
            <md-content class="md-scrollbar">
                <div class="dialog-content">
                    <div class="row">
                        <div class="col l-6 m-6 c-6">
                            <div class="form-control">
                                <md-autocomplete v-model="customerName" @md-selected="getCustomerSelected" :md-options="customers" @md-changed="getCustomers"  @md-opened="getCustomers" :class="{'md-invalid': submitted && $v.entity.customerId.$error }">
                                    <label>Khách hàng</label>
                                    <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                        <md-highlight-text :md-term="term">{{ item.companyAlias }}</md-highlight-text>
                                    </template>
                                    <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                        "{{ term }}" Không tìm thấy!
                                    </template>
                                    <span class="md-error" v-if="submitted && !$v.entity.customerId.isSelected">Vui lòng chọn khách hàng</span>
                                </md-autocomplete>
                                <md-button @click="openCustomer()" class="md-icon-button">
                                    <md-icon>manage_search</md-icon>
                                </md-button>
                            </div>
                        </div>
                        <div class="col l-6 m-6 c-6">
                            <div class="form-control">
                                <md-autocomplete v-model="ownerName" @md-selected="getOwnerSelected" :md-options="owners" @md-changed="getOwners"  @md-opened="getOwners">
                                    <label>Chủ hàng</label>
                                    <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                        <md-highlight-text :md-term="term">{{ item.companyAlias }}</md-highlight-text>
                                    </template>
                                    <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                        "{{ term }}" Không tìm thấy!
                                    </template>
                                </md-autocomplete>
                                <md-button @click="openOwner()" class="md-icon-button">
                                    <md-icon>manage_search</md-icon>
                                </md-button>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col l-6 m-6 c-6">
                            <div class="form-control">
                                <md-autocomplete v-model="goodsName" @md-selected="getGoodsSelected" :md-options="goods" @md-changed="getGoods"  @md-opened="getGoods">
                                    <label>Hàng hóa</label>
                                    <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                        <md-highlight-text :md-term="term">{{ item.goodsName }}</md-highlight-text>
                                    </template>
                                    <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                        "{{ term }}" Không tìm thấy!
                                    </template>
                                </md-autocomplete>
                                <md-button @click="openGoods()" class="md-icon-button">
                                    <md-icon>manage_search</md-icon>
                                    <md-tooltip>Tìm hàng hóa</md-tooltip>
                                </md-button>
                            </div>
                        </div>
                        <div class="col l-6 m-6 c-6">
                            <div class="form-control">
                                <md-autocomplete v-model="feeName" @md-selected="getFeeSelected" :md-options="fees" @md-changed="getFees" @md-opened="getFees" :class="{'md-invalid': submitted && $v.entity.feeId.$error }">
                                    <label>Loại phí</label>
                                    <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                        <md-highlight-text :md-term="term">{{ item.feeName }}</md-highlight-text>
                                    </template>
                                    <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                        "{{ term }}" Không tìm thấy!
                                    </template>
                                    <span class="md-error" v-if="submitted && !$v.entity.feeId.isSelected">Vui lòng chọn loại phí</span>
                                </md-autocomplete>
                                <md-button @click="openFee()" class="md-icon-button">
                                    <md-icon>manage_search</md-icon>
                                </md-button>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col l-4 m-4 c-12">
                            <div class="form-control">
                                <md-autocomplete v-model="transportTypeName" @md-selected="getTransportTypeSelected" :md-options="transportTypes" @md-changed="getTransportTypes"  @md-opened="getTransportTypes" :class="{'md-invalid': submitted && $v.entity.transportTypeId.$error }">
                                    <label>Loại hình</label>
                                    <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                        <md-highlight-text :md-term="term">{{ item.title }}</md-highlight-text>
                                    </template>
                                    <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                        "{{ term }}" Không tìm thấy!
                                    </template>
                                    <span class="md-error" v-if="submitted && !$v.entity.transportTypeId.isSelected">Vui lòng chọn loại hình</span>
                                </md-autocomplete>
                                <md-button @click="openTransportType()" class="md-icon-button">
                                    <md-icon>manage_search</md-icon>
                                </md-button>
                            </div>
                        </div>
                        <div class="col l-4 m-4 c-12">
                            <div class="form-control">
                                <md-autocomplete v-model="goodsTypeName" @md-selected="getGoodsTypeSelected" :md-options="goodsTypes" @md-changed="getGoodsTypes"  @md-opened="getGoodsTypes" :class="{'md-invalid': submitted && $v.entity.goodsTypeId.$error }">
                                    <label>Loại hàng</label>
                                    <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                        <md-highlight-text :md-term="term">{{ item.title }}</md-highlight-text>
                                    </template>
                                    <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                        "{{ term }}" Không tìm thấy!
                                    </template>
                                    <span class="md-error" v-if="submitted && !$v.entity.goodsTypeId.isSelected">Vui lòng chọn loại hàng</span>
                                </md-autocomplete>
                                <md-button @click="openGoodsType()" class="md-icon-button">
                                    <md-icon>manage_search</md-icon>
                                </md-button>
                            </div>
                        </div>
                        <div class="col l-4 m-4 c-12">
                            <div class="form-control">
                                <md-autocomplete v-model="unitName" @md-selected="getUnitSelected" :md-options="units" @md-changed="getUnits"  @md-opened="getUnits" :class="{'md-invalid': submitted && $v.entity.unitId.$error }">
                                    <label>Đơn vị</label>
                                    <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                        <md-highlight-text :md-term="term">{{ item.unitName }}</md-highlight-text>
                                    </template>
                                    <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                        "{{ term }}" Không tìm thấy!
                                    </template>
                                    <span class="md-error" v-if="submitted && !$v.entity.unitId.isSelected">Vui lòng chọn đợn vị hàng hóa</span>
                                </md-autocomplete>
                                <md-button @click="openUnit()" class="md-icon-button">
                                    <md-icon>manage_search</md-icon>
                                </md-button>
                            </div>  
                        </div>
                    </div>
                    <div class="row">
                        <div class="col l-6 m-6 c-6">
                            <div class="form-control">
                                <md-autocomplete v-model="currencyName" @md-selected="getCurrencySelected" :md-options="currencys" @md-changed="getCurrencys" @md-opened="getCurrencys" :class="{'md-invalid': submitted && $v.entity.currencyId.$error }">
                                    <label>Tiền tệ</label>
                                    <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                        <md-highlight-text :md-term="term">{{ item.currencyName }}</md-highlight-text>
                                    </template>
                                    <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                        "{{ term }}" Không tìm thấy!
                                    </template>
                                    <span class="md-error" v-if="submitted && !$v.entity.currencyId.isSelected">Vui lòng chọn tiền tệ</span>
                                </md-autocomplete>
                                <md-button @click="openCurrency()" class="md-icon-button">
                                    <md-icon>manage_search</md-icon>
                                </md-button>
                            </div>
                        </div>
                        <div class="col l-6 m-6 c-12">
                            <md-field :class="{'md-invalid': submitted && $v.entity.unitPrice.$error }">
                                <label for="unitPrice">Phí</label>
                                <number @change="calSum()" v-model="entity.unitPrice" v-bind="currencyF" class="form-control currency"></number> 
                                <span class="md-error" v-if="submitted && !$v.entity.unitPrice.required">Vui lòng nhập phí</span>
                            </md-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col l-6 m-6 c-12">
                            <md-field :class="{'md-invalid': submitted && $v.entity.vat.$error }">
                                <label for="vat">VAT</label>
                                <number @change="calSum()" v-model="entity.vat" v-bind="currencyF" class="form-control currency"></number> 
                                <span class="md-error" v-if="submitted && !$v.entity.unitPrice.required">Vui lòng nhập vat</span>
                            </md-field>
                        </div>
                        <div class="col l-6 m-6 c-12">
                            <md-field class="number-control">
                                <div>Thành tiền:</div>
                                <number name="amount" v-model="entity.amount" v-bind="currencyF" disabled></number> 
                            </md-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col l-4 m-4 c-4">
                            <div class="form-control">
                                <md-autocomplete v-model="sectorReceiptContName" @md-selected="getSectorReceiptContSelected" :md-options="sectorReceiptConts" @md-changed="getSectorReceiptConts"  @md-opened="getSectorReceiptConts">
                                    <label>Khu vực nơi lấy cont rỗng</label>
                                    <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                        <md-highlight-text :md-term="term">{{ item.sectorName }}</md-highlight-text>
                                    </template>
                                    <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                        "{{ term }}" Không tìm thấy!
                                    </template>
                                </md-autocomplete>
                                <md-button @click="openSectorReceiptCont()" class="md-icon-button">
                                    <md-icon>manage_search</md-icon>
                                </md-button>
                            </div>
                        </div>
                        <div class="col l-4 m-4 c-4">
                            <div class="form-control">
                                <md-autocomplete v-model="sectorReceiptGoodsName" @md-selected="getSectorReceiptGoodsSelected" :md-options="sectorReceiptGoodss" @md-changed="getSectorReceiptGoods"  @md-opened="getSectorReceiptGoods">
                                    <label>Khu vực nhận/đóng hàng</label>
                                    <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                        <md-highlight-text :md-term="term">{{ item.sectorName }}</md-highlight-text>
                                    </template>
                                    <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                        "{{ term }}" Không tìm thấy!
                                    </template>
                                </md-autocomplete>
                                <md-button @click="openSectorReceiptGoods()" class="md-icon-button">
                                    <md-icon>manage_search</md-icon>
                                </md-button>
                            </div>
                        </div>
                        <div class="col l-4 m-4 c-4">
                            <div class="form-control">
                                <md-autocomplete v-model="sectorTransitDeliveryName" @md-selected="getSectorTransitDeliverySelected" :md-options="sectorTransitDeliverys" @md-changed="getSectorTransitDelivery"  @md-opened="getSectorTransitDelivery">
                                    <label>Khu vực cảng đi</label>
                                    <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                        <md-highlight-text :md-term="term">{{ item.sectorName }}</md-highlight-text>
                                    </template>
                                    <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                        "{{ term }}" Không tìm thấy!
                                    </template>
                                </md-autocomplete>
                                <md-button @click="openSectorTransitDelivery()" class="md-icon-button">
                                    <md-icon>manage_search</md-icon>
                                </md-button>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col l-4 m-4 c-4">
                            <div class="form-control">
                                <md-autocomplete v-model="sectorTransitReceiptName" @md-selected="getSectorTransitReceiptSelected" :md-options="sectorTransitReceipts" @md-changed="getSectorTransitReceipt"  @md-opened="getSectorTransitReceipt">
                                    <label>Khu vực cảng đến</label>
                                    <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                        <md-highlight-text :md-term="term">{{ item.sectorName }}</md-highlight-text>
                                    </template>
                                    <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                        "{{ term }}" Không tìm thấy!
                                    </template>
                                </md-autocomplete>
                                <md-button @click="openSectorTransitReceipt()" class="md-icon-button">
                                    <md-icon>manage_search</md-icon>
                                </md-button>
                            </div>
                        </div>
                        <div class="col l-4 m-4 c-4">
                            <div class="form-control">
                                <md-autocomplete v-model="sectorDeliveryGoodsName" @md-selected="getSectorDeliveryGoodsSelected" :md-options="sectorDeliveryGoodss" @md-changed="getSectorDeliveryGoods"  @md-opened="getSectorDeliveryGoods">
                                    <label>Khu vực trả hàng</label>
                                    <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                        <md-highlight-text :md-term="term">{{ item.sectorName }}</md-highlight-text>
                                    </template>
                                    <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                        "{{ term }}" Không tìm thấy!
                                    </template>
                                </md-autocomplete>
                                <md-button @click="openSectorDeliveryGoods()" class="md-icon-button">
                                    <md-icon>manage_search</md-icon>
                                </md-button>
                            </div>
                        </div>
                        <div class="col l-4 m-4 c-4">
                            <div class="form-control">
                                <md-autocomplete v-model="sectorDeliveryContName" @md-selected="getSectorDeliveryContSelected" :md-options="sectorDeliveryConts" @md-changed="getSectorDeliveryConts"  @md-opened="getSectorDeliveryConts">
                                    <label>Khu vực nơi trả cont rỗng</label>
                                    <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                        <md-highlight-text :md-term="term">{{ item.sectorName }}</md-highlight-text>
                                    </template>
                                    <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                        "{{ term }}" Không tìm thấy!
                                    </template>
                                </md-autocomplete>
                                <md-button @click="openSectorDeliveryCont()" class="md-icon-button">
                                    <md-icon>manage_search</md-icon>
                                </md-button>
                            </div>
                        </div>
                    </div>
                </div>
            </md-content>
            <div class="dialog-actions">
                <md-checkbox v-if="id == 0" v-model="saveAndCreate" class="md-primary">Lưu và tạo mới</md-checkbox>
                <md-button v-shortkey="['ctrl', 'l']" @shortkey="submit()" @click="submit()" class="md-raised md-primary"><span>L</span>ưu<md-tooltip>Lưu (Ctrl + L)</md-tooltip></md-button>
                <md-button v-shortkey="['ctrl', 'đ']" @shortkey="showDialog = false" @click="showDialog = false" class="md-raised"><span>Đ</span>óng<md-tooltip>Đóng (Ctrl + Đ)</md-tooltip></md-button>
            </div>
        </md-content>
        <currencyList ref="currencyList" title="Tiền tệ" @close="closeCurrency"/>
        <feeList ref="feeList" title="Phí làm hàng" @close="closeFee"/>
        <transportTypeList ref="transportTypeList" title="Loại hàng" @close="closeTransportType"/>
        <goodsTypeList ref="goodsTypeList" @close="closeGoodsType"/>
        <unitList ref="unitList" @close="closeUnit"/>
        <ownerList ref="ownerList" title="chủ hàng" :type="ownerType" @close="closeOwner"/>
        <customerList ref="customerList" title="khách hàng" :type="customerType" @close="closeCustomer"/>
        <goodsList ref="goodsList" @close="closeGoods"/>

        <sectorReceiptContList ref="sectorReceiptContList" @close="closeSectorReceiptCont"/>
        <sectorReceiptGoodsList ref="sectorReceiptGoodsList" @close="closeSectorReceiptGoods"/>
        <sectorTransitDeliveryList ref="sectorTransitDeliveryList" @close="closeSectorTransitDelivery"/>
        <sectorTransitReceiptList ref="sectorTransitReceiptList" @close="closeSectorTransitReceipt"/>
        <sectorDeliveryGoodsList ref="sectorDeliveryGoodsList" @close="closeSectorDeliveryGoods"/>
        <sectorDeliveryContList ref="sectorDeliveryContList" @close="closeSectorDeliveryCont"/>
    </md-dialog>
</template>
<script>
    import goodsService from '../../../api/goodsService';
    import sectorService from '../../../api/sectorService';
    import unitService from '../../../api/unitService';
    import companyService from '../../../api/companyService';
    import settingDebtService from '../../../api/settingDebtService';
    import feeService from '../../../api/feeService';
    import currencyService from '../../../api/currencyService';
    import transportPlanService from '../../../api/transportPlanService';
    import messageBox from '../../../utils/messageBox';
    import { required } from 'vuelidate/lib/validators';
    import { mapActions } from 'vuex';
    import common from '../../../mixins';
    import currencyList from '../../../components/popup/_CurrencyList.vue';
    import feeList from '../../../components/popup/_FeeList.vue';
    import transportTypeList from '../../../components/popup/_TransportPlanList.vue';
    import goodsTypeList from '../../../components/popup/_TransportTypeList.vue';
    import unitList from '../../../components/popup/_UnitList.vue';
    import ownerList from '../../../components/popup/_CompanyList.vue';
    import customerList from '../../../components/popup/_CompanyList.vue';
    import goodsList from '../../../components/popup/_GoodsList.vue';
    import sectorReceiptContList from '../../../components/popup/_SectorList.vue';
    import sectorReceiptGoodsList from '../../../components/popup/_SectorList.vue';
    import sectorTransitDeliveryList from '../../../components/popup/_SectorList.vue';
    import sectorTransitReceiptList from '../../../components/popup/_SectorList.vue';
    import sectorDeliveryGoodsList from '../../../components/popup/_SectorList.vue';
    import sectorDeliveryContList from '../../../components/popup/_SectorList.vue';

    export default ({
        components: {
            currencyList,
            feeList,
            transportTypeList,
            goodsTypeList,
            unitList,
            customerList,
            ownerList,
            goodsList,
            sectorReceiptContList,
            sectorReceiptGoodsList,
            sectorTransitDeliveryList,
            sectorTransitReceiptList,
            sectorDeliveryGoodsList,
            sectorDeliveryContList
        },
        metaInfo: {
            title: 'Thêm/Cập nhật thiết lập phí khách hàng'
        },
        data() {
            return {
                showDialog: false,
                title: '',
                id: 0,
                submitted: false,
                entity: { id: 0, contractId: null, customerId: 0, goodsId: null, ownerId: null, feeId: 0, fee: { feeName: '' }, currencyId: 0, transportTypeId: null, goodsTypeId: null, unitId: 0, unitPrice: 0, rate: 0, vat: 0, amount: 0 },
                saveAndCreate: false,
                categoryName: '',
                categories: [],
                currencyName: '',
                feeName: '',
                fees: [],
                currencys: [],
                unitName: '',
                units: [],
                goodsTypeName: '',
                goodsTypes: [],
                transportTypes: [],
                transportTypeName: '',
                currencyF: common.currencyF2,
                customerType: common.companyType.customer,
                ownerType: common.companyType.customer,
                customerName: '',
                customers: [],
                ownerName: '',
                owners: [],
                goodsName: '',
                goods: [],
                sectorTransitDeliveryName: '',
                sectorTransitDeliverys: [],
                sectorTransitReceiptName: '',
                sectorTransitReceipts: [],
                sectorReceiptGoodsName: '',
                sectorReceiptGoodss: [],
                sectorDeliveryGoodsName: '',
                sectorDeliveryGoodss: [],
                sectorDeliveryContName: '',
                sectorDeliveryConts: [],
                sectorReceiptContName: '',
                sectorReceiptConts: []
            }
        },
        methods: {
            ...mapActions('common', ['setLoading']),

            calSum(){
                const total = this.entity.currency.rate * parseFloat(this.entity.unitPrice);
                this.entity.amount = total + (total * (this.entity.vat / 100));
            },

            async open(id){
                this.id = id;
                if(id == 0){
                    this.title = 'Thêm mới thiết lập phí khách hàng';
                    this.entity = { id: 0, contractId: null, customerId: 0, goodsId: null, ownerId: null, feeId: 0, fee: null, currencyId: 0, transportTypeId: null, goodsTypeId: null, unitId: 0, unitPrice: 0, rate: 0, vat: 0, amount: 0 };
                }
                else {
                    this.saveAndCreate = false;
                    this.title = 'Cập nhật thiết lập phí khách hàng';
                    this.getById();
                }
                this.submitted = false;
                this.showDialog = true;
            },

            //Goods
            closeGoods(item){
                this.goodsName = item.goodsName;
                this.entity.goods = item;
                this.entity.goodsId = item.id;
                this.$refs.goodsList.close();
            },

            getGoodsSelected(val){
                this.entity.goods = val;
                this.entity.goodsId = val.id;
                this.goodsName = val.goodsName;
            },

            openGoods(){
                this.$refs.goodsList.open();
            },

            getGoods(searchTerm){
                let search = { pageIndex: 1, pageSize: 30, code:  searchTerm };
                goodsService.getGoodss(search).then((response) => {
                    if(response.statusCode == 200){
                        this.goods = response.data.items;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },

            //Customer
            closeCustomer(item){
                this.customerName = item.companyAlias;
                this.entity.customerId = item.id;
                this.$refs.customerList.close();
                this.entity.contractId = 0;
                this.contractName = '';
                this.projectName = '';
                this.entity.projectId = 0;
            },

            getCustomerSelected(val){
                this.entity.customerId = val.id;
                this.customerName = val.companyAlias;
                this.entity.contractId = 0;
                this.contractName = '';
                this.projectName = '';
                this.entity.projectId = 0;
            },

            openCustomer(){
                this.$refs.customerList.open();
            },

            getCustomers(searchTerm){
                let search = { pageIndex: 1, pageSize: 30, companyType: common.companyType.customer, alias: searchTerm };
                companyService.getCompanies(search).then((response) => {
                    if(response.statusCode == 200){
                        this.customers = response.data.items;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },

            //Owner
            closeOwner(item){
                this.ownerName = item.companyAlias;
                this.entity.ownerId = item.id;
                this.$refs.ownerList.close();
            },

            getOwnerSelected(val){
                this.entity.ownerId = val.id;
                this.ownerName = val.companyAlias;
            },

            openOwner(){
                this.$refs.ownerList.open();
            },

            getOwners(searchTerm){
                let search = { pageIndex: 1, pageSize: 30, companyType: common.companyType.customer, code: searchTerm };
                companyService.getCompanies(search).then((response) => {
                    if(response.statusCode == 200){
                        this.owners = response.data.items;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },

            //Unit
            closeUnit(item){
                this.unitName = item.unitName;
                this.entity.unit = item;
                this.entity.unitId = item.id;
                this.$refs.unitList.close();
            },

            openUnit(){
                this.$refs.unitList.open();
            },

            getUnitSelected(val){
                this.entity.unitId = val.id;
                this.unitName = val.unitName;
                this.entity.unit = val;
            },
            
            getUnits(searchTerm){
                let search = { pageIndex: 1, pageSize: 30, code: searchTerm };
                unitService.getUnits(search).then((response) => {
                    if(response.statusCode == 200){
                        this.units = response.data.items;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },

            //Goods Type
            closeGoodsType(item){
                this.goodsTypeName = item.title;
                this.entity.goodsType = item;
                this.entity.goodsTypeId = item.id;
                this.$refs.goodsTypeList.close();
            },

            openGoodsType(){
                this.$refs.goodsTypeList.open();
            },

            getGoodsTypeSelected(val){
                this.entity.goodsTypeId = val.id;
                this.goodsTypeName = val.title;
                this.entity.goodsType = val;
            },

            getGoodsTypes(searchTerm){
                let search = { pageIndex: 1, pageSize: 30, type: 2, title: searchTerm };
                transportPlanService.getTransportPlans(search).then((response) => {
                    if(response.statusCode == 200){
                        this.goodsTypes = response.data.items;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },

            //Transport Plan
            closeTransportType(item){
                this.transportTypeName = item.title;
                this.entity.transportTypeId = item.id;
                this.entity.transportType = item;
                this.$refs.transportTypeList.close();
            },

            getTransportTypeSelected(val){
                this.entity.transportTypeId = val.id;
                this.transportTypeName = val.title;
                this.entity.transportType = val;
            },

            openTransportType(){
                this.$refs.transportTypeList.open(6);
            },

            getTransportTypes(searchTerm){
                let search = { pageIndex: 1, pageSize: 30, type: 6, title: searchTerm };
                transportPlanService.getTransportPlans(search).then((response) => {
                    if(response.statusCode == 200){
                        this.transportTypes = response.data.items;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },

            close(){
                this.showDialog = false;
            },

            //Fee
            closeFee(item){
                this.feeName = item.feeName;
                this.entity.feeId = item.id;
                this.entity.fee = item;
                this.$refs.feeList.close();
            },

            getFeeSelected(val){
                this.entity.feeId = val.id;
                this.entity.fee = val;
                this.feeName = val.feeName;
            },

            openFee(){
                this.$refs.feeList.open(12);
            },

            getFees(searchTerm){
                let search = { pageIndex: 1, pageSize: 30, categoryId: 12, code: searchTerm };
                feeService.getFees(search).then((response) => {
                    if(response.statusCode == 200){
                        this.fees = response.data.items;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },

           //Currency
           closeCurrency(item){
                this.currencyName = item.currencyName;
                this.entity.currency = item;
                this.entity.currencyId = item.id;
                this.entity.rate = item.rate;
                this.$refs.currencyList.close();
            },

            getCurrencySelected(val){
                this.entity.currency = val;
                this.entity.currencyId = val.id;
                this.entity.rate = val.rate;
                this.currencyName = val.currencyName;
            },

            openCurrency(){
                this.$refs.currencyList.open();
            },

            getCurrencys(searchTerm){
                let search = { pageIndex: 1, pageSize: 30, code: searchTerm };
                currencyService.getCurrency(search).then((response) => {
                    if(response.statusCode == 200){
                        this.currencys = response.data.items;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },

            submit(){
                this.submitted = true;
                this.$v.$touch();
                if (this.$v.$invalid) {
                    return;
                }
                this.$emit('close', this.entity, this.saveAndCreate);
            },

            getById(){
                this.setLoading(true);
                settingDebtService.getById(this.id).then((response) => {
                    if(response.statusCode == 200){
                        this.entity = response.data;
                        this.currencyName = response.data.currency.currencyName;
                        this.feeName = response.data.fee.feeName;
                        this.transportTypeName = response.data.transportType.title;
                        this.goodsTypeName = response.data.goodsType.title;
                        this.unitName = response.data.unit.unitName;
                        if(this.entity.customerId > 0){
                            this.customerName = response.data.customer.companyAlias;
                        }
                        if(this.entity.contractId > 0){
                            this.contractName = response.data.contract.contractName;
                        }
                        if(this.entity.ownerId > 0){
                            this.ownerName = response.data.owner.companyAlias;
                        }
                        if(this.entity.sectorReceiptContId > 0){
                            this.sectorReceiptContName = response.data.sectorReceiptCont.sectorName;
                        }
                        if(this.entity.sectorDeliveryContId > 0){
                            this.sectorDeliveryContName = response.data.sectorDeliveryCont.sectorName;
                        }
                        if(this.entity.sectorReceiptGoodsId > 0){
                            this.sectorReceiptGoodsName = response.data.sectorReceiptGoods.sectorName;
                        }
                        if(this.entity.sectorDeliveryGoodsId > 0){
                            this.sectorDeliveryGoodsName = response.data.sectorDeliveryGoods.sectorName;
                        }
                        if(this.entity.sectorTransitReceiptId > 0){
                            this.sectorTransitReceiptName = response.data.sectorTransitReceipt.sectorName;
                        }
                        if(this.entity.sectorTransitDeliveryId > 0){
                            this.sectorTransitDeliveryName = response.data.sectorTransitDelivery.sectorName;
                        }
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            //Cảng đi
            closeSectorTransitDelivery(item){
                this.sectorTransitDeliveryName = item.sectorName;
                this.entity.sectorTransitDeliveryId = item.id;
                this.entity.sectorTransitDelivery = item;
                this.$refs.sectorTransitDeliveryList.close();
            },

            openSectorTransitDelivery(){
                this.$refs.sectorTransitDeliveryList.open(0);
            },

            getSectorTransitDeliverySelected(val){
                this.entity.sectorTransitDeliveryId = val.id;
                this.entity.sectorTransitDelivery = val;
                this.sectorTransitDeliveryName = val.sectorName;
            },
            
            getSectorTransitDelivery(searchTerm){
                let search = { pageIndex: 1, pageSize: 30, type: 0, code:  searchTerm };
                sectorService.filter(search).then((response) => {
                    if(response.statusCode == 200){
                        this.sectorTransitDeliverys = response.data.items;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },

            //Cảng đến
            closeSectorTransitReceipt(item){
                this.sectorTransitReceiptName = item.sectorName;
                this.entity.sectorTransitReceiptId = item.id;
                this.entity.sectorTransitReceipt = item;
                this.$refs.sectorTransitReceiptList.close();
            },

            openSectorTransitReceipt(){
                this.$refs.sectorTransitReceiptList.open(0);
            },

            getSectorTransitReceiptSelected(val){
                this.entity.sectorTransitReceiptId = val.id;
                this.entity.sectorTransitReceipt = val;
                this.sectorTransitReceiptName = val.sectorName;
            },
            
            getSectorTransitReceipt(searchTerm){
                let search = { pageIndex: 1, pageSize: 30, type: 0, code:  searchTerm };
                sectorService.filter(search).then((response) => {
                    if(response.statusCode == 200){
                        this.sectorTransitReceipts = response.data.items;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },

            //Nơi Nhận hàng
            closeSectorReceiptGoods(item){
                this.sectorReceiptGoodsName = item.sectorName;
                this.entity.sectorReceiptGoodsId = item.id;
                this.entity.sectorReceiptGoods = item;
                this.$refs.sectorReceiptGoodsList.close();
            },

            openSectorReceiptGoods(){
                this.$refs.sectorReceiptGoodsList.open(0);
            },

            getSectorReceiptGoodsSelected(val){
                this.entity.sectorReceiptGoodsId = val.id;
                this.entity.sectorReceiptGoods = val;
                this.sectorReceiptGoodsName = val.sectorName;
            },
            
            getSectorReceiptGoods(searchTerm){
                let search = { pageIndex: 1, pageSize: 30, type: 0, code:  searchTerm };
                sectorService.filter(search).then((response) => {
                    if(response.statusCode == 200){
                        this.sectorReceiptGoodss = response.data.items;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },

            //Nơi trả hàng
            closeSectorDeliveryGoods(item){
                this.sectorDeliveryGoodsName = item.sectorName;
                this.entity.sectorDeliveryGoodsId = item.id;
                this.entity.sectorDeliveryGoods = item;
                this.$refs.sectorDeliveryGoodsList.close();
            },

            openSectorDeliveryGoods(){
                this.$refs.sectorDeliveryGoodsList.open(0);
            },

            getSectorDeliveryGoodsSelected(val){
                this.entity.sectorDeliveryGoodsId = val.id;
                this.entity.sectorDeliveryGoods = val;
                this.sectorDeliveryGoodsName = val.sectorName;
            },
            
            getSectorDeliveryGoods(searchTerm){
                let search = { pageIndex: 1, pageSize: 30, type: 0, code:  searchTerm };
                sectorService.filter(search).then((response) => {
                    if(response.statusCode == 200){
                        this.sectorDeliveryGoodss = response.data.items;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },

            //Nơi nhận cont
            closeSectorReceiptCont(item){
                this.sectorReceiptContName = item.sectorName;
                this.entity.sectorReceiptContId = item.id;
                this.entity.sectorReceiptConts = item;
                this.$refs.sectorReceiptContList.close();
            },

            openSectorReceiptCont(){
                this.$refs.sectorReceiptContList.open(0);
            },

            getSectorReceiptContSelected(val){
                this.entity.sectorReceiptContId = val.id;
                this.entity.sectorReceiptConts = val;
                this.sectorReceiptContName = val.sectorName;
            },
            
            getSectorReceiptConts(searchTerm){
                let search = { pageIndex: 1, pageSize: 30, type: 0, code:  searchTerm };
                sectorService.filter(search).then((response) => {
                    if(response.statusCode == 200){
                        this.sectorReceiptConts = response.data.items;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },

            //Nơi trả cont
            closeSectorDeliveryCont(item){
                this.sectorDeliveryContName = item.sectorName;
                this.entity.sectorDeliveryContId = item.id;
                this.entity.sectorDeliveryConts = item;
                this.$refs.sectorDeliveryContList.close();
            },

            openSectorDeliveryCont(){
                this.$refs.sectorDeliveryContList.open(0);
            },

            getSectorDeliveryContSelected(val){
                this.entity.sectorReceiptContId = val.id;
                this.entity.sectorReceiptConts = val;
                this.sectorReceiptContName = val.sectorName;
            },
            
            getSectorDeliveryConts(searchTerm){
                let search = { pageIndex: 1, pageSize: 30, type: 0, code:  searchTerm };
                sectorService.filter(search).then((response) => {
                    if(response.statusCode == 200){
                        this.sectorDeliveryConts = response.data.items;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },

            back(){
                this.$router.push('/fee');
            }
        },
        watch: { 
            goodsName: function (val) { 
                if(val == ''){
                    this.entity.goodsId = null;
                }
            },
            transportTypeName: function (val) { 
                if(val == ''){
                    this.entity.transportTypeId = 0;
                }
            },
            feeName: function (val){
                if(val == ''){
                    this.entity.customerId = null;
                }
            },
            goodsTypeName: function (val) { 
                if(val == ''){
                    this.entity.goodsTypeId = 0;
                }
            },
            unitName: function (val) { 
                if(val == ''){
                    this.entity.unitId = 0;
                }
            },
            customerName: function (val){
                if(val == ''){
                    this.entity.customerId = 0;
                    this.customerName = '';
                }
            },
            ownerName: function (val) { 
                if(val == ''){
                    this.entity.ownerId = null;
                }
            },
            sectorDeliveryContName: function (val) { 
                if(val == ''){
                    this.entity.sectorDeliveryContId = null;
                    this.sectorDeliveryContName = '';
                }
            },
            sectorReceiptContName: function (val) { 
                if(val == ''){
                    this.entity.sectorReceiptContId = null;
                    this.sectorReceiptContName = '';
                }
            },
            sectorDeliveryGoodsName: function (val) { 
                if(val == ''){
                    this.entity.sectorDeliveryGoodsId = null;
                    this.sectorDeliveryGoodsName = '';
                }
            },
            sectorReceiptGoodsName: function (val) { 
                if(val == ''){
                    this.entity.sectorReceiptGoodsId = null;
                    this.sectorReceiptGoodsName = '';
                }
            },
            sectorTransitDeliveryName: function (val) { 
                if(val == ''){
                    this.entity.sectorTransitDeliveryId = null;
                    this.sectorTransitDeliveryName = '';
                }
            },
            sectorTransitReceiptName: function (val) { 
                if(val == ''){
                    this.entity.sectorTransitReceiptId = null;
                    this.sectorTransitReceiptName = '';
                }
            },
        },
        validations: {
            entity: {
                customerId: {
                    required,
                    isSelected(value) {
                        if (value > 0) {
                            return true;
                        }
                        else{
                            return false;
                        }
                    }
                },
                feeId: {
                    required,
                    isSelected(value) {
                        if (value > 0) {
                            return true;
                        }
                        else{
                            return false;
                        }
                    }
                },
                currencyId: {
                    required,
                    isSelected(value) {
                        if (value > 0) {
                            return true;
                        }
                        else{
                            return false;
                        }
                    }
                },
                transportTypeId: {
                    required,
                    isSelected(value) {
                        if (value > 0) {
                            return true;
                        }
                        else{
                            return false;
                        }
                    }
                },
                goodsTypeId: {
                    required,
                    isSelected(value) {
                        if (value > 0) {
                            return true;
                        }
                        else{
                            return false;
                        }
                    }
                },
                unitId: {
                    required,
                    isSelected(value) {
                        if (value > 0) {
                            return true;
                        }
                        else{
                            return false;
                        }
                    }
                },
                unitPrice: {
                    required
                },
                vat: {
                    required
                },
            }
       }
     })

</script>


<style lang="css" scoped>
    .dialog-title {
        font-size: 16px;
    }
    .dialog-header {
        border-bottom: 1px solid #ccc;
        height: 40px;
        display: flex;
        align-items: center;
        padding: 0 15px;
    }
    .md-content {
        width: 800px;
        height: 650px;
        max-width: 800px;
    }
    .md-scrollbar {
        height: 560px;
        padding-bottom: 20px;
    }
    .dialog-content {
        padding: 10px 15px;
    }
    .dialog-actions {
        height: 35px;
        border-top: 1px solid #ccc;
        padding-top: 15px;
        display: flex;
        align-items: center;
        justify-content: end;
    }
</style>